import React from "react"
import Layout from "../../components/layout"
import Form from "../../components/Form"
import Breadcrumbs from "../../components/Breadcrumbs"
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as styles from "./librederm.module.css"

import LibredermImage from "../../assets/images/librederm/LibredermImage.jpg"
import LibredermImage2 from "../../assets/images/librederm/ImageLibrederm2.jpg"
import Buyers from "../../assets/images/librederm/buyers.svg"
import Buyer from "../../assets/images/librederm/buyer.svg"
import Couple from "../../assets/images/librederm/couple.svg"
import decorLeftImage from "../../assets/images/librederm/decoreLeft.svg"
import decorRightImage from "../../assets/images/librederm/decoreRight.svg"
import tubeImage from "../../assets/images/librederm/tubeImage.svg"

const Librederm = ({ pageContext }) => {
  const { t } = useTranslation()
  return (
    <Layout>
      <div className={styles.wrapper}>
        <section>
          <div className={styles.container}>
            <Breadcrumbs pageContext={pageContext} title="Librederm" />
            <h2 className={`${styles.title} ${styles.sectionTitle__title}`}>
              Увеличили продажи на&nbsp;24% с&nbsp;помощью performance-рекламы
            </h2>
          </div>
          <img
            className={`${styles.mobile__img} ${styles.sectionTitle__img} `}
            src={LibredermImage}
            alt="LibredermImage"
          />
          <div className={styles.container}>
            <img
              className={styles.sectionTitle__img}
              src={LibredermImage}
              alt="LibredermImage"
            />
            <p className={`${styles.text} ${styles.firstText}`}>
              Привлечь и&nbsp;удержать аудиторию конкурентов только лишь
              обычными инструментами маркетплейсов&nbsp;&mdash; нелегко. Для
              усиления трафика на&nbsp;маркетплейсах и&nbsp;увеличения доли
              новой аудитории команда бренда LIBREDERM обратилась
              в&nbsp;DVGROUP. Нам предстояло найти новый сегмент покупателей
              и&nbsp;переключить аудиторию брендов-конкурентов на&nbsp;линейку
              SERACIN LIBREDERM, при этом добиться ROAS выше 2,48.
            </p>
            <p className={styles.text}>
              Перед запуском внешнего трафика по&nbsp;локальным сегментам
              мы&nbsp;всегда проверяем количество товара, доступное для заказа.
              А&nbsp;также оптимизируем карточки товаров на&nbsp;маркетплейсах:
              наполняем их&nbsp;контентом, смотрим на&nbsp;количество отзывов
              и&nbsp;качество визуала.
            </p>
            <p className={styles.text}>
              Перед запуском внешнего трафика по&nbsp;локальным сегментам
              мы&nbsp;всегда проверяем количество товара, доступное для заказа.
              А&nbsp;также оптимизируем карточки товаров на&nbsp;маркетплейсах:
              наполняем их&nbsp;контентом, смотрим на&nbsp;количество отзывов
              и&nbsp;качество визуала.
            </p>
            <div className={styles.block__info}>
              <div>
                <div className={styles.container__img}>
                  <img
                    className={styles.block__info__img}
                    src={Buyers}
                    alt="Покупатели брендов"
                  />
                </div>

                <h3 className={styles.block__info__title}>
                  Покупатели в&nbsp;категории за&nbsp;последние полгода
                </h3>
              </div>
              <div>
                <div className={styles.container__img}>
                  <img
                    className={styles.block__info__img}
                    src={Buyer}
                    alt="Покупатели конкурентов "
                  />
                </div>

                <h3 className={styles.block__info__title}>
                  Покупатели брендов конкурентов за&nbsp;последние полгода
                </h3>
              </div>
              <div>
                <div className={styles.container__img}>
                  <img
                    className={styles.block__info__img}
                    src={Couple}
                    alt="Look-a-like"
                  />
                </div>

                <h3 className={styles.block__info__title}>
                  LAL на&nbsp;аудиторную подборку покупателей бренда Librederm
                </h3>
              </div>
            </div>
            <p className={styles.text}>
              Выбрали сплит каналов&nbsp;VK реклама и&nbsp;Yandex РСЯ для
              лучшего результата.
              <br />
              и&nbsp;запустили performance-рекламу.
            </p>
            <p className={styles.text}>
              Транслировали в&nbsp;креативах два товара вместе&nbsp;&mdash;
              очищающий гель для умывания и&nbsp;крем-гель. Выделили
              на&nbsp;продукты скидку до&nbsp;30%. Так покупатель сразу понимал,
              что это полноценный двухступенчатый уход и&nbsp;купить набором
              выходит выгоднее. Объявления из&nbsp;двух продуктов показали
              хороший результат, CTR = 1,96%.
            </p>
          </div>
          <img
            className={`${styles.mobile__img} ${styles.section__img} `}
            src={LibredermImage2}
            alt="LibredermImage"
          />
          <div className={styles.container}>
            <img
              className={styles.section__img}
              src={LibredermImage2}
              alt="LibredermImage"
            />
          </div>
        </section>
        <article>
          <div className={styles.divider}></div>
          <div className={styles.contentContainer}>
            <div className={styles.content}>
              <img
                src={decorLeftImage}
                alt="Decor left"
                className={`${styles.decor} ${styles.decorLeft}`}
              />
              <div className={`${styles.textBlock} ${styles.textBlockGreen}`}>
                <p>
                  Учитывали особенности экономики бренда: CPO для нового клиента
                  могла быть выше в&nbsp;3&nbsp;раза
                  <br className={styles.transition} /> из-за длинного&nbsp;LT
                </p>
              </div>
              <div className={`${styles.textBlock} ${styles.textBlockWhite}`}>
                <p>
                  Наблюдали за&nbsp;динамикой и&nbsp;назначали разную предельную
                  ставку в зависимости от&nbsp;ценности клиента
                </p>
              </div>
              <img
                src={decorRightImage}
                alt="Decor right"
                className={`${styles.decor} ${styles.decorRight}`}
              />
            </div>
          </div>
          <div className={styles.divider}></div>
        </article>
        <article className={styles.articleTextBox}>
          <div className={styles.container}>
            <p className={styles.text}>
              CDP даёт нам возможность не&nbsp;только собрать сегменты,
              но&nbsp;и&nbsp;оценить прибыль, которую приносит один клиент,
              проанализировать путь покупателя, а&nbsp;также выяснить, как
              и&nbsp;где он&nbsp;осуществил покупку. Кроме того, мы&nbsp;можем
              наблюдать за&nbsp;продажами после завершения рекламной кампании,
              если пользователь оказался в&nbsp;нашем сегменте.
            </p>
            <p className={styles.text}>
              Рекламная кампания показала положительный результат,
              мы&nbsp;увеличили продажи на&nbsp;24% линейки SERACIN
              и&nbsp;перевыполнили ROAS. А&nbsp;также за&nbsp;время рекламной
              кампании увеличилась доля сопутствующих товаров.
            </p>
          </div>
        </article>
        <article>
          <div className={styles.divider}></div>
          <div className={styles.statisticsWrapper}>
            <img
              src={tubeImage}
              alt="tubeImage"
              className={styles.productImage}
            />
            <div className={styles.container}>
              <div className={styles.statCardsContainer}>
                <div className={styles.statCard}>
                  <span className={styles.statValue}>19%</span>
                  <p className={styles.statDescr}>
                    перевыполнение плана по&nbsp;ROAS
                  </p>
                </div>
                <div className={styles.statCard}>
                  <span className={styles.statValue}>+24%</span>
                  <p className={styles.statDescr}>
                    продажи линейки Серацин по&nbsp;сравнению с&nbsp;2022 годом
                  </p>
                </div>
                <div className={styles.statCard}>
                  <span className={styles.statValue}>2,95</span>
                  <p className={styles.statDescr}>средний ROAS</p>
                </div>
                <div className={styles.statCard}>
                  <span className={styles.statValue}>+36%</span>
                  <p className={styles.statDescr}>рост выручки</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.divider}></div>
        </article>
        <article className={styles.articleLast}>
          <div className={styles.container}>
            <p className={styles.text}>
              При запуске внешнего трафика по&nbsp;локальным сегментам важно
              не&nbsp;забывать и&nbsp;про внутренние инструменты. Комплексный
              подход, сочетающий внешнюю и&nbsp;внутреннюю стратегию, даёт
              наилучший результат.
            </p>
          </div>
        </article>
      </div>
      <Form t={t} />
    </Layout>
  )
}

export default Librederm
